.site-view {
  // in the inline mode we want to have image + header to be displayed on one screen
  height: 100vh;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .header-container {
    flex: 0 0 auto;
  }

  .site-details-container {
    flex: 1 0 0;
    overflow-y: hidden;
  }
}
