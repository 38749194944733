@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/form/all";
@import "~bulma/sass/elements/button.sass";

.site-submission-view {
  height: 100%;

  .site-submission-view-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    .site-submission-form-container {
      width: 75%;
      height: 100%;
      text-align: center;

      .site-submission-form {
        display: flex;
        flex-direction: column;
        padding: 1em;
      }
    }
  }
}
