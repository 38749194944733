@import "../../constants";

.siteDetails {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &ContentContainer {
    height: 100%;
    position: relative;

    display: flex;
    justify-items: center;

    @media #{$tablet-and-less} {
      flex-direction: column;
    }

    .imageContainer {
      background: black;

      @media #{$tablet-and-less} {
        //On desktop the image should shrink to guarantee sidebar's fixed width
        //but on mobile we want to ensure maximal image size
        flex-shrink: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @media #{$tablet-and-less} {
          //Minimal reasonable image height to avoid flat and tiny landscape images
          min-height: 40vh;
          object-fit: cover;
        }
      }

      &Fullscreen {
        max-width: 100% !important;
        height: auto !important;
      }
    }

    &Inline {
      padding: 10px;

      .imageContainer {
        background: unset;

        img {
          object-position: left top;
        }
      }
    }

    .closeIcon {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 1.6em;
      cursor: pointer;

      @media #{$tablet-and-less} {
        color: white;
      }
    }

  }

  //TODO: why this is here and not in modal.tsx?
  &Dialog {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 70%);

    .siteDetailsContentContainer {
      max-width: 80vw;
      max-height: 80vh;

      @media #{$tablet-and-less} {
        max-width: 95vw;
        max-height: 95vh;
      }

      justify-content: center;
      overflow: hidden !important;

      &Loading {
        visibility: hidden;
      }
    }
  }
}
