.signupForm {
  padding: 5em;

  .joinTripspirationsLabel {
    font-size: 3em;
    font-weight: 600;
    text-align: center;
  }

  .alreadyHaveAccountLabel {
    text-align: center;
    margin-bottom: 1.5em;
  }

  :global(.field) {
    :global(.help) {
      color: red;
    }
  }
}
