@import "../../constants";

$separator-border: 1px solid gainsboro;

.favoritesSection {
  flex: 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: $separator-border;
  border-top: $separator-border;

  .heartAndNumberContainer {
    display: flex;
    font-size: 1.3em;
  }

  .heartIconContainer {
    color: $color-favorites;
    cursor: pointer;
    margin-right: 0.15em;

    &AddedToFavorites {
      animation-name: heartbeat;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }

    @keyframes heartbeat {
      50% {
        transform: scale(1.25);
      }

      to {
        transform: scale(1);
      }
    }
  }

  .servicesLinksContainer {
    display: flex;
    justify-content: center;
    padding-top: 0.25em;

    .logo {
      margin-right: 3px;
      display: block;
      cursor: pointer;
      height: 24px;
      width: auto;
    }
  }
}
