@import "../../../constants";

.headerMenu {
  &Icon {
    font-size: 22px;
    color: $color-light-blue;
    cursor: pointer;
  }

  &Inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 400px;
    padding: 80px 2.5em;
    font-size: 20px;
    font-weight: bold;
    background-color: $beluga;
    overflow: hidden;
    transition: ease 0.3s;

    @media #{$mobile} {
      width: 90%;
    }

    &Close {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 20px;
      color: $lucky-grey;
      cursor: pointer;
    }

    &Link {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      color: $lucky-grey;
      transition: 0.1s ease-in-out;

      &:hover {
        color: $carbon;
      }

      &Icon {
        width: 20px;
      }
    }

    &Panel {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;

      &Link {
        display: flex;
        gap: 12px;
        align-items: center;
        transition: 0.1s ease-in-out;

        &:hover {
          color: $color-light-blue;
        }

        &Icon {
          width: 20px;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $steam;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(16 16 16 / 50%);
  transition: ease 0.3s;
}

.isOpened {
  z-index: 999;
  opacity: 1;

  .headerMenuInner {
    right: 0;
  }
}

.isClosed {
  z-index: -100;
  opacity: 0;

  .headerMenuInner {
    right: -400px;

    @media #{$mobile} {
      right: -90%;
    }
  }
}
