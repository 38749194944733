.image-overlay {
  width: 100%;
  height: 60px;
  overflow: hidden;

  .site-name {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
}
