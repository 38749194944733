@import "../../constants";

.trending-cities {
  border: 1px solid $color-light-border;
  background: $color-lotion;
  padding: 15px;

  &-header {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  &-list {
    display: flex;

    &-entry {
      margin-right: 15px;
      font-weight: 800;
    }
  }
}
