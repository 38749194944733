@import "../../constants";

.siteDescription {
  flex: 1 0 auto;
  //flex-basis: auto combined with flex-grow: 1 and height/min-height
  //will enable the element to grow as much as possible while still being scrollable
  //(wouldn't be scrollable without height set), see: https://stackoverflow.com/a/14964944
  height: 100px; //minimum height we want to keep for the description
  overflow-y: auto;

  font-size: 1.2em;

  p {
    margin: 0;
  }

  .markdownLink {
    text-decoration: none;
    color: inherit;

    &:hover .quotationText {
      border-bottom: 0.5px dashed gray;
    }

    .quotationSource {
      white-space: nowrap;
    }

    &:hover .quotationSource {
      background: $color-light-blue-transparent;
    }
  }
}
