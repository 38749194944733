@import "../../constants";

.landingPage {
  .sitesListTitle {
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    margin: 1em 0;
  }

  .heroContainer {
    width: 100%;
    height: 50vh;

    @media #{$tablet-and-less} {
      height: 40vh;
    }

    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .heroBox {
      @media #{$desktop} {
        width: 50%;
      }

      @media #{$tablet} {
        width: 70%;
      }

      @media #{$mobile} {
        width: 90%;
      }

      .siteTitle {
        color: white;
        font-size: 2.5em;
        font-weight: 900;
      }

      .siteDescription {
        color: white;
        font-size: 1.2em;
        font-weight: 500;
      }

      &Section {
        margin-bottom: 15px;
      }

      .searchBar {
        width: 80%;
        max-width: 650px;
      }
    }
  }

  .contentContainer {
    margin: 0 2.5em;
  }
}
