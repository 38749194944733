@import "../../constants";

.header {
  padding: 10px 2.5em;
  background-color: $color-lotion;

  &Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    &Logo {
      height: 3em;
      cursor: pointer;
    }

    &Search {
      width: 33%;
    }

    &Panel {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
