@import "../../constants";

$separator-border: 1px dotted gainsboro;

//On desktop the content are should keep the fixed horizontal space on the right of the picture
//On smaller screens it should be pushed underneath the picture.
.siteDetailsSidebarContainer {
  height: 100%;
  width: 400px;
  padding: 20px;

  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  @media #{$tablet-and-less} {
    width: 100%;
    padding: 5px 5px;
  }

  .headerContainer {
    flex: 0;
    margin-top: 10px;
    position: relative;

    .titleContainer {
      font-weight: 700;

      &Row {
        margin: 0 0 0.3em;

        &Title {
          font-size: 1.5em;
        }

        &LocalizedTitle {
          font-size: 1.1em;
          font-style: italic;
          color: gainsboro;
        }

        &City {
          font-size: 1.1em;
          font-weight: 700;
        }
      }
    }
  }

  .photoDetailsContainer {
    padding: 10px 0;
    border-top: $separator-border;
    display: flex;

    @media #{$tablet-and-less} {
      //on mobile devices we want photo details section to appear first, right below the picture
      order: -1;
      //and appear with a bottom border as well
      border-bottom: $separator-border;
      //while removing the upper border
      border-top: unset;

      padding: 5px;
    }

    .leftArrowContainer {
      display: flex;
      align-items: center;
      margin: 0 10px 0 5px;

      .leftArrowSymbol {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);

        @media #{$tablet-and-less} {
          //on mobile devices we want the arrow to point up, towards the picture
          transform: rotate(-135deg);
        }
      }
    }

    .photoDetailsInnerContainer {
      flex: 1;

      .photoCredits {
        font-weight: 800;
      }

      .photoDescription {
        font-style: italic;
        margin: 8px 0;
      }
    }
  }
}
