@import "../../constants";

.cityImage {
  position: relative;
  cursor: pointer;

  img {
    display: block;
    margin: 0 auto;

    @media #{$desktop} {
      // We need to set some reasonbalbe min-heigh here so that the images below are not loaded
      // prematurely when the page is initially blank.
      min-height: 300px;
    }

    @media #{$tablet} {
      min-height: 250px;
    }

    @media #{$mobile} {
      min-height: 150px;
    }
  }

  .cityName {
    font-family: Spectral, serif;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-weight: 700;

    @media #{$desktop} {
      font-size: 60px;
    }

    @media #{$tablet} {
      font-size: 50px;
    }

    @media #{$mobile} {
      font-size: 30px;
    }
  }
}
