$mobile: "(max-width: 480px)";
$tablet: "(min-width: 481px) and (max-width: 1024px)";
$desktop: "(min-width: 1025px)";
$mobile-and-less: "(max-width: 479px)";
$mobile-and-more: "(min-width: 480px)";
$tablet-and-less: "(max-width: 1024px)";
$tablet-and-more: "(min-width: 481px)";
$desktop-and-more: "(min-width: 1025px)";

.font-serif {
  font-family: Spectral, serif;
}

$color-favorites: #f55252;
$color-light-border: #eee;
$color-light-blue: #538fef;
$color-light-blue-transparent: rgb(83 143 239 / 15%);
$color-disabled: #ccc;
$color-lotion: #fafafa;
$beluga: #f1f1f1;
$lucky-grey: #767676;
$steam: #ddd;
$shovel-knight: #34c2fe;
$carbon: #333;
