@import "../../constants";

.cityView {
  .contentContainer {
    margin: 0 2.5em;
  }

  .mainImage {
    margin-bottom: 0.6em;

    @media #{$mobile} {
      width: 100%;
    }

    @media #{$tablet} {
      width: 80%;
    }

    @media #{$desktop} {
      width: 60%;
    }
  }
}
