.footer {
  flex-shrink: 0;
  height: 60px;
  background: #eeeeef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  margin-top: 20px;
  text-align: right;

  &Links {
    display: flex;
    margin-left: 35px;

    &Element {
      margin: 0 15px 0 0;
    }
  }

  &Copyright {
    margin-right: 35px;
  }
}
