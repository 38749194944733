.loginForm {
  padding: 20px;

  .loginToTripspirationsLabel {
    font-size: 3em;
    font-weight: 600;
    text-align: center;
  }

  :global(.field) {
    :global(.help) {
      color: red;
    }
  }
}
