@import "../../constants";

.searchBar {
  :global(.react-autosuggest__container) {
    position: relative;
  }

  :global(.react-autosuggest__input) {
    width: 100%;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 1em;
    border: 1px solid #aaa;
    border-radius: 3px;
  }

  :global(.react-autosuggest__input--open) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  :global(.react-autosuggest__input--focused) {
    outline: none;
  }

  :global(.react-autosuggest__suggestions-container) {
    display: none;
  }

  :global(.react-autosuggest__suggestions-container--open) {
    display: block;
    position: absolute;
    top: 39px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    border-radius: 0 0 3px 3px;
    z-index: 2;
  }

  :global(.react-autosuggest__suggestions-list) {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  :global(.react-autosuggest__suggestion) {
    cursor: pointer;
    padding: 10px 20px;
  }

  :global(.react-autosuggest__suggestion--highlighted) {
    background-color: $color-light-border;
  }
}
