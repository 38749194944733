@import "../../constants";

.user-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: $color-light-blue;
  text-align: center;
  line-height: 35px;
  cursor: pointer;

  .user-icon-letter {
    text-transform: uppercase;
    color: white;
  }
}
