.basicViewContainer {
  .imageAndChildrenContainer {
    height: 100%;
    display: flex;

    .message {
      height: 1.5em;

      &-error {
        color: red;
      }
    }

    .heroImage {
      height: 100%;
      flex-basis: 66%;
      margin: 0.5em 0 0.5em 1em;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .childrenContainer {
      height: 100%;
      flex-basis: 33%;

      :global(.button.is-primary) {
        width: 191px;
        height: 46px;
      }
    }
  }
}
