@import "../../constants";

.gallery {
  background: $color-lotion;

  .col-layout-images-container {
    column-gap: 5px;

    @media #{$mobile} {
      column-count: 1;
    }

    @media #{$tablet} {
      column-count: 2;
    }

    @media #{$desktop} {
      column-count: 3;
    }
  }

  .overlay-image-container {
    position: relative;
    overflow: hidden;

    .col-layout-image {
      transition: transform 0.4s ease-out;

      &:hover {
        transform: scale(1.05);
      }

      cursor: pointer;
      width: 100%;
      display: block;
      padding-bottom: 5px;
      min-height: 150px; //for lazy loading reasons on the initial flash of content

      @media #{$desktop} {
        min-height: 200px;
      }
    }

    .overlay {
      position: absolute;
      opacity: 0;
      bottom: 5px;
      color: white;
      background: rgb(16 16 16 / 50%);
    }

    &:hover .overlay {
      transition: opacity 0.75s ease-out;
      opacity: 1;
    }
  }
}
