.favoritesView {
  height: 100%;
  display: flex;
  flex-direction: column;

  .contentContainer {
    flex: 1;
    margin: 0 2.5em;
  }

  .specialMessageContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
