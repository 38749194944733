@import "~normalize-css/normalize.css";
@import "constants";

//Initializing and customizing Bulma

$primary: $color-light-blue;

@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/components/dropdown";
@import "~bulma/sass/grid/columns";

//TODO: try to use the below imports that actually use (login, signup, etc) if that changes anything
@import "~bulma/sass/elements/icon.sass";
@import "~bulma/sass/form/all";

//Aplying border-box globaly as the only right box-sizing ;-)
* {
  box-sizing: border-box;
}

// global element customizations
a {
  text-decoration: none;
  color: #00bfff;
}

//Custom page styles
body {
  //Setting up main app font
  font-family: Montserrat, sans-serif;

  #modal {
    position: relative;
    z-index: 999;
  }

  &.modal-open {
    overflow: hidden;
  }

  h2 {
    font-weight: bold;
  }
}

//Flex-based sticky footer implementation
html {
  body {
    min-height: 100vh;

    #root {
      height: 100%;

      .app {
        height: 100%;

        .app--inner-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        #main-container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .content-container {
          flex: 1;
        }
      }
    }
  }
}
